import { mapActions, mapGetters } from 'vuex'
import { classFaq } from '@/constant'
import { currencyFormat, isNotEmptyError, showVueToast } from '@/utils'
const CONFIG = {
  email: 'Email',
  name: 'Full name',
  mobilePhone: 'Phone number'
}

export default {
  name: 'career-counching',
  components: {
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    HorizontalProduct: () => import(/* webpackChunkName: "image" */ '@/components/card/HorizontalProductCard'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Dropdown: () => import(/* webpackChunkName: "DropDown" */ '@/components/dropdown/Dropdown'),
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion'),
    TimeOutline2: () => import(/* webpackChunkName: "icon" */ '@/components/icons/TimeOutline2'),
    Chevron: () => import(/* webpackChunkName: "Chevron" */ '@/components/icons/Chevron'),
    School: () => import(/* webpackChunkName: "school" */ '@/components/icons/School'),
    Rocket: () => import(/* webpackChunkName: "rocket" */ '@/components/icons/Rocket'),
    CreditCard: () => import(/* webpackChunkName: "credit-card" */ '@/components/icons/CreditCard'),
    Cash: () => import(/* webpackChunkName: "cash" */ '@/components/icons/Cash'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton')
  },
  data() {
    return {
      fullBootcampSlug: ['fullstack-web', 'fullstack-data', 'digital-marketing'],
      fullBootcamp: [
        {
          url1: '',
          name: '',
          durationText: '',
          programName: '',
          price: ''
        },
        {
          url1: '',
          name: '',
          durationText: '',
          programName: '',
          price: ''
        },
        {
          url1: '',
          name: '',
          durationText: '',
          programName: '',
          price: ''
        }
      ],
      buttonDisabled: false,
      isBookSession: false,
      faq: classFaq,
      isError: false,
      programIndex: 0,
      visibleStepModal: false,
      registrationPayload: {
        name: null,
        email: null,
        mobilePhone: null
      },
      isGeneralRegister: false,
      inputDisabled: false,
      phoneInputType: 'number',
      isTyping: false,
      privateConsultationId: null,
      selectedSchedule: null,
      notification: null,
      scheduleList: [],
      upcomingSchedule: [],
      errors: [],
      programWebDetail: {},
      customerId: 1, // G2
      formToken: '',
      errorValidation: {
        name: true,
        email: true,
        mobilePhone: true
      },
      carouselPaymentOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        perPage: 4,
        speed: 1000,
        gap: '0.75rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1,
            fixedWidth: '290px'
          }
        }
      },
      paymentOptions: [
        {
          title: 'Cash',
          image: 'cash',
          desc: 'Pay the full tution fee upfront before starting your journey at G2Academy.',
          buttonText: 'Learn More'
        },
        {
          title: 'ISA',
          image: 'isa',
          desc: 'Study now and pay tuition fee after receiving a Job Commitment',
          buttonText: 'Learn More'
        },
        {
          title: 'Scholarship',
          image: 'school',
          desc: 'We have a Scholarship option for high achievers with top scores.',
          buttonText: 'Learn More'
        },
        {
          title: 'Installment',
          image: 'credit',
          desc: 'Enjoy flexible installment schemes with our trusted payment partners.',
          buttonText: 'Learn More'
        },
        {
          title: 'Credit Card',
          image: 'credit',
          desc: 'Experience seamless purchasing power with our credit card payment option.',
          buttonText: 'Learn More'
        }
      ]
    }
  },
  filters: {
    currencyFormat
  },
  computed: {
    ...mapGetters('common', ['isMobile', 'adminB2C']),
    ...mapGetters('auth', ['isLoggedIn']),
    studentId() {
      return localStorage.getItem('student_id')
    },
    environtment() {
      return process.env.NODE_ENV
    },
    registrationPayloadName() {
      return this.registrationPayload.name
    },
    registrationPayloadEmail() {
      return this.registrationPayload.email
    },
    registrationPayloadMobilePhone() {
      return this.registrationPayload.mobilePhone
    }
  },
  watch: {
    registrationPayloadName: function (value) {
      if (!value) {
        this.errors['name'] = 'Name is required!'
        this.errorValidation.name = true
      } else {
        this.errors['name'] = undefined
        this.errorValidation.name = false
      }
    },
    registrationPayloadEmail(value) {
      if (!value) {
        this.errors['email'] = 'Email is required!'
        this.errorValidation.email = true
      } else {
        // eslint-disable-next-line
        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
        if (regex.test(value) === false) {
          this.errors['email'] = 'Email format is invalid!'
          this.errorValidation.email = true
        } else {
          this.errors['email'] = undefined
          this.errorValidation.email = false
        }
      }
    },
    registrationPayloadMobilePhone(value) {
      if (value && value.length >= 15) {
        this.registrationPayload.mobilePhone = value.slice(0, 15)
        this.phoneInputType = 'text'
      } else {
        this.phoneInputType = 'number'
      }
      if (value && (value[0] !== '0' || value[1] !== '8')) {
        this.errors['mobilePhone'] = 'Must start with 08xxxx'
        this.errorValidation.mobilePhone = true
      } else if (value && value.length < 10) {
        this.errors['mobilePhone'] = 'Minimum 10 digits, Maximum 15 digits'
        this.errorValidation.mobilePhone = true
      } else {
        this.errors['mobilePhone'] = undefined
        this.errorValidation.mobilePhone = false
      }
    }
  },
  mounted() {
    this.getContent(this.fullBootcampSlug).then((res) => {
      this.fullBootcamp = res
    })
    this.getClassInformation()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading', 'sendWhatsApp']),
    ...mapActions('program', ['getProgramByWebSlug', 'getFormToken', 'newEnrollV2', 'enrollBookSession']),
    ...mapActions('student', ['getStudentDetail', 'getSchedule']),
    prepareConsultation() {
      this.getFormToken()
        .then((response) => {
          this.formToken = response.data.data
          this.getScheduleList()
        })
        .catch(() => showVueToast('There was a problem, please try again later!', 'error', 3000))
    },
    registerNow(value) {
      value ? (this.isBookSession = false) : (this.isBookSession = true)
      if (this.isLoggedIn && !this.isBookSession) {
        this.register()
      }
    },
    getClassInformation() {
      this.getProgramByWebSlug({ slug: 'private-consultation' })
        .then((res) => {
          this.privateConsultationId = res.data.data.id
          this.prepareConsultation()
        })
        .catch(() => (this.isError = true))
    },
    getScheduleList() {
      this.showLoading()
      const params = {
        customer_id: this.customerId,
        core_class_id: this.privateConsultationId,
        sortBy: 'startAt',
        max_participants_not_yet_full: true
      }
      this.getSchedule({
        params,
        formToken: this.formToken
      }).then((response) => {
        this.scheduleList = response.data.data
        this.hideLoading()
      })
    },
    bookPrivateConsultation() {
      this.buttonDisabled = true
      this.showLoading()
      const forThankYouPage = 'book-session'
      this.getFormToken().then((response) => {
        this.formToken = response.data.data
        this.enrollBookSession({
          payloads: {
            coreClassId: this.privateConsultationId,
            lmsClassId: this.selectedSchedule.id,
            email: this.registrationPayload.email,
            name: this.registrationPayload.name,
            mobilePhone: this.registrationPayload.mobilePhone
          },
          formToken: this.formToken
        }).then(
          () => {
            showVueToast('Your session is successfully registered!', 'success', 3000)
            setTimeout(() => {
              this.hideLoading()
              this.buttonDisabled = false
              window.location.reload()
            }, 2000)
          },
          (error) => this.ifError(error)
        )
      })
    },
    goToDetail: function (item) {
      if (item.title === 'Cash') {
        this.sendWhatsApp({ number: this.adminB2C, topic: 'Pembiayaan Bootcamp Secara Cash' })
      } else if (item.title === 'Credit Card') {
        this.sendWhatsApp({ number: this.adminB2C, topic: 'Pembiayaan Bootcamp Via Kartu Kredit' })
      } else if (item.title === 'ISA') {
        this.$router.push('/isa')
      } else if (item.title === 'Scholarship') {
        this.$router.push('/tech-champ-scholarship')
      } else if (item.title === 'Installment') {
        this.$router.push('/installment')
      }
    },
    emailCheck(email) {
      // eslint-disable-next-line
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (regex.test(email) === false) {
        this.errors['email'] = 'Email format is invalid!'
      }
      return regex.test(email)
    },
    async ifError(error) {
      let errorMessage = ''
      if (error.response.status === 404) {
        errorMessage = 'Diagnostic Assessment schedule is not available. Please contact our Customer Service!'
      } else if (error.response.status === 500) {
        errorMessage = 'You have already taken this program, please check your dashboard!'
      } else {
        errorMessage = 'Program registration failed, please try again!'
      }
      showVueToast(errorMessage, 'error', 3000)
      await setTimeout(() => {
        this.buttonDisabled = false
        this.hideLoading()
      }, 6000)
    },
    scrollToForm: function () {
      var formScroll = document.getElementById('register-form')
      const position = formScroll.getBoundingClientRect()
      window.scrollTo(position.left, position.top + window.scrollY - 145)
    },
    async getContent(URLs) {
      return Promise.all(URLs.map(this.getClassData))
    },
    toggleStepModal() {
      this.visibleStepModal = !this.visibleStepModal
    },
    async getClassData(slug) {
      const result = await this.getClassBySlug(slug)
      return result
    },
    async getClassBySlug(slug) {
      const result = await this.getProgramByWebSlug({
        slug
      })

      if (result.status === 200) {
        return result.data.data
      } else {
        return false
      }
    }
  }
}
